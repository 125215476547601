// ------------------------------------
// --------- PRIVATE METHODS ----------
// ------------------------------------
const load = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    // console.log('ERROR @localStorage.load', err);
    return undefined;
  }
};

const save = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // console.log('ERROR @localStorage.save', err);
  }
};

const clear = (storageKey: string) => {
  try {
    if (localStorage.getItem(storageKey) !== null) {
      localStorage.removeItem(storageKey);
    }
  } catch (err) {
    // console.log('ERROR @localStorage.clear', err);
  }
};

const clearAll = () => {
  try {
    localStorage.clear();
  } catch (err) {
    // console.log('ERROR @localStorage.clear', err);
  }
};

// ------------------------------------
// ---------- PUBLIC METHODS ----------
// ------------------------------------
export const localStorageLoad = (storageKey: string) => load(storageKey);

export const localStorageSave = (storageKey: string, value: any) => {
  save(storageKey, value);
};

export const localStorageClear = (storageKey: string) => {
  clear(storageKey);
};

export const localStorageClearAll = () => {
  clearAll();
};
export const localStorageSync = (
  storageKey: string,
  newObj: Record<string, any>,
) => {
  let current: any = localStorageLoad(storageKey);
  localStorageSave(storageKey, {
    ...current,
    ...newObj,
  });
};
