import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../utils/LocalStorageHandler/localStorageKeys';
import {
  localStorageClear,
  localStorageLoad,
  localStorageSync,
} from '../../../utils/LocalStorageHandler/localStorageUtils';
import { EvaluationStep } from '../../model/forms/types';
import { EvaluationSettingsState } from './types';

const persisted: EvaluationSettingsState = localStorageLoad(
  LocalStorageKeys.evaluationSettings,
);

export const initialState: EvaluationSettingsState = {
  title: persisted?.title ?? '',
  steps: persisted?.steps ?? [],
  currentStudentId: persisted?.currentStudentId ?? '',
};

const evaluationSettingsSlice = createSlice({
  name: 'evaluationSettings',
  initialState,
  reducers: {
    setCurrentStudentId(state, action: PayloadAction<string>) {
      state.currentStudentId = action.payload;
      localStorageSync(LocalStorageKeys.evaluationSettings, {
        title: state.currentStudentId,
      });
    },
    setSteps(state, action: PayloadAction<EvaluationStep[]>) {
      state.steps = action.payload;
      localStorageSync(LocalStorageKeys.evaluationSettings, {
        steps: state.steps,
      });
    },
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
      localStorageSync(LocalStorageKeys.evaluationSettings, {
        title: state.title,
      });
    },
    resetSettings(state) {
      state.title = '';
      state.steps = [];
      localStorageClear(LocalStorageKeys.evaluationSettings);
    },
  },
});

// Exports
export const { name: sliceKey, actions } = evaluationSettingsSlice;
export default evaluationSettingsSlice.reducer;
