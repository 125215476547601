/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { InjectedReducersType } from '../utils/types/injector-typings';
import { history } from './history';
import apiDataReducer, {
  sliceKey as apiDataKey,
} from '../app/features/ApiData/slice';
import configReducer, {
  sliceKey as configKey,
} from '../app/features/Config/slice';
import examSettingsReducer, {
  sliceKey as examSettingsKey,
} from '../app/features/ExamSettings/slice';
import studentEvaluationsReducer, {
  sliceKey as studentEvaluationsKey,
} from '../app/features/StudentEvaluations/slice';
import evaluationStepsReducer, {
  sliceKey as evaluationStepsKey,
} from '../app/features/EvaluationSettings/slice';
import staticContentReducer, {
  sliceKey as staticContentKey,
} from '../app/features/StaticContent';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    // other non-injected reducers can go here...
    router: connectRouter(history),
    [apiDataKey]: apiDataReducer,
    [configKey]: configReducer,
    [examSettingsKey]: examSettingsReducer,
    [studentEvaluationsKey]: studentEvaluationsReducer,
    [evaluationStepsKey]: evaluationStepsReducer,
    [staticContentKey]: staticContentReducer,
  });
}
