import { PayloadAction } from '@reduxjs/toolkit';
import { StemTaskPayload } from '.';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../utils/LocalStorageHandler/localStorageKeys';
import {
  localStorageClear,
  localStorageLoad,
  localStorageSync,
} from '../../../utils/LocalStorageHandler/localStorageUtils';
import { StudentEvaluationSchema } from '../../model/schemas/ExamSettings/StudentEvaluationSchema';
import {
  AnceintLanguagePayload,
  ModernLanguageSubsectionPayload,
  ModernLanguageSupersectionPayload,
  StemCommentsPayload,
  StudentEvaluationCollectionSchema,
  StudentEvaluationsState,
  SubtaskPayload,
} from './types';

const KEY = LocalStorageKeys.studentsEvaluations;
// Sync with localstorage for studentsEvaluations
const persisted: StudentEvaluationsState = localStorageLoad(KEY);
export const initialState: StudentEvaluationsState = {
  evaluations: persisted?.evaluations ?? {},
  userId: persisted?.userId ?? '',
};
const studentEvaluationsSlice = createSlice({
  name: 'studentEvaluations',
  initialState,
  reducers: {
    setEvaluationsState(state, action: PayloadAction<StudentEvaluationsState>) {
      state.evaluations = action.payload.evaluations;
      state.userId = action.payload.userId;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
        userId: state.userId,
      });
    },
    setEvaluations(
      state,
      action: PayloadAction<StudentEvaluationCollectionSchema>,
    ) {
      state.evaluations = action.payload;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
      localStorageSync(KEY, {
        userId: state.userId,
      });
    },
    updateEvaluation(state, action: PayloadAction<StudentEvaluationSchema>) {
      state.evaluations[action.payload.id] = action.payload;
      const keys = Object.keys(action.payload);
      keys.forEach(key => {
        state.evaluations[action.payload.id][key] = action.payload[key];
      });
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    resetEvaluations(state) {
      state.evaluations = {};
      state.userId = '';
      localStorageClear(KEY);
    },
    updateSubtask(state, action: PayloadAction<SubtaskPayload>) {
      const studentId = action.payload.studentId;
      const subtaskId = action.payload.subtaskId;
      const baseTaskName = action.payload.baseTaskName;
      const partId = action.payload.partId;
      const content = action.payload.content;

      if (partId) {
        state.evaluations[studentId].taskEvaluations[baseTaskName].parts[
          partId
        ].subtasks[subtaskId] = content;
      } else {
        state.evaluations[studentId].taskEvaluations[baseTaskName].subtasks[
          subtaskId
        ] = content;
      }

      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateStemTask(state, action: PayloadAction<StemTaskPayload>) {
      const studentId = action.payload.studentId;
      const taskIndex = action.payload.taskIndex;
      const content = action.payload.content;

      state.evaluations[studentId].stemTaskSet.tasks[taskIndex] = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateGeneralComments(state, action: PayloadAction<StemCommentsPayload>) {
      const studentId = action.payload.studentId;
      const content = action.payload.content;
      state.evaluations[studentId].comments = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateModernLanguageSupersection(
      state,
      action: PayloadAction<ModernLanguageSupersectionPayload>,
    ) {
      const studentId = action.payload.studentId;
      const step = action.payload.step;
      const content = action.payload.content;
      const parts = state.evaluations[studentId].modernLanguageParts;
      const partIndex = parts.findIndex(part => part.id === step.partId);
      const supersectionIndex = parts[partIndex].supersections.findIndex(
        supersection => (supersection.id = step.supersectionId),
      );
      parts[partIndex].supersections[supersectionIndex] = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateModernLanguageSubsection(
      state,
      action: PayloadAction<ModernLanguageSubsectionPayload>,
    ) {
      const studentId = action.payload.studentId;
      const step = action.payload.step;
      const content = action.payload.content;
      const parts = state.evaluations[studentId].modernLanguageParts;
      const partIndex = parts.findIndex(part => part.id === step.partId);
      const supersectionIndex = parts[partIndex].supersections.findIndex(
        supersection => supersection.id === step.supersectionId,
      );
      const supersection = parts[partIndex].supersections[supersectionIndex];
      const sectionIndex = supersection.sections.findIndex(
        section => section.id === step.sectionId,
      );
      const subsectionIndex = supersection.sections[
        sectionIndex
      ].subsections.findIndex(
        subsection => subsection.id === step.subsectionId,
      );
      supersection.sections[sectionIndex].subsections[
        subsectionIndex
      ] = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateAncientLanguageTextIndexing(
      state,
      action: PayloadAction<AnceintLanguagePayload>,
    ) {
      const studentId = action.payload.studentId;
      const content = action.payload.content;
      state.evaluations[studentId].ancientLanguageIndexingForm = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateAncientLanguageTextInterpretation(
      state,
      action: PayloadAction<AnceintLanguagePayload>,
    ) {
      const studentId = action.payload.studentId;
      const content = action.payload.content;
      state.evaluations[studentId].ancientLanguageInterpretationForm = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
    updateAncientLanguageTextTranslation(
      state,
      action: PayloadAction<AnceintLanguagePayload>,
    ) {
      const studentId = action.payload.studentId;
      const content = action.payload.content;
      state.evaluations[studentId].ancientLanguageTranslationForm = content;
      localStorageSync(KEY, {
        evaluations: state.evaluations,
      });
    },
  },
});

// Exports
export const { name: sliceKey, actions } = studentEvaluationsSlice;
export default studentEvaluationsSlice.reducer;
