import { PayloadAction } from '@reduxjs/toolkit';
import { DrupalSectionTypes } from 'app/api/utils/constants';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../utils/LocalStorageHandler/localStorageKeys';
import {
  localStorageClear,
  localStorageLoad,
  localStorageSync,
} from '../../../utils/LocalStorageHandler/localStorageUtils';
import {
  ArtsAndMusicNumberOfSubsectionsInput,
  ArtsAndMusicTaskSet,
} from '../../model/schemas/ExamSettings/ArtsAndMusicTaskSet';
import {
  ExamSchema,
  initialExam,
} from '../../model/schemas/ExamSettings/ExamSchema';
import {
  GroupedPercentagesSchema,
  PercentageGroup,
  PercentageSchema,
} from '../../model/schemas/ExamSettings/GroupedPercentagesSchema';
import {
  initialStemTaskSet,
  StemTaskSet,
} from '../../model/schemas/ExamSettings/StemTaskSchemas';
import {
  initialSubject,
  SubjectSchema,
} from '../../model/schemas/ExamSettings/SubjectSchema';
import { TeacherSchema } from '../../model/schemas/ExamSettings/TeacherSchema';
import { DrupalNode } from '../ApiData';
import {
  AncientLanguageDefaults,
  ExamSettingsState,
  TaskPercentagesSchema,
} from './types';

// pass state from localstorage if exist
const persistedExamSettings = localStorageLoad(LocalStorageKeys.examSettings);

export const initialState: ExamSettingsState = {
  teacher: persistedExamSettings?.teacher as TeacherSchema,
  subject: persistedExamSettings?.subject as SubjectSchema,
  exam: persistedExamSettings?.exam as ExamSchema,
  subtaskPercentages: persistedExamSettings?.subtaskPercentages as GroupedPercentagesSchema,
  multipartPercentages: persistedExamSettings?.multipartPercentages as GroupedPercentagesSchema,
  taskPercentages: persistedExamSettings?.taskPercentages as TaskPercentagesSchema,
  stemTasks: persistedExamSettings?.stemTasks as StemTaskSet,
  artsAndMusicTaskSet: persistedExamSettings?.artsAndMusicTaskSet as ArtsAndMusicTaskSet,
  modernLanguageTasks: persistedExamSettings?.modernLanguageTasks as Record<
    string,
    string[]
  >,
  modernLanguageTaskWeights: persistedExamSettings?.modernLanguageTaskWeights as GroupedPercentagesSchema,
  ancientLanguageDefaults: persistedExamSettings?.ancientLanguageDefaults as AncientLanguageDefaults,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const examSettingsSlice = createSlice({
  name: 'examSettings',
  initialState,
  reducers: {
    setTeacher(state, action: PayloadAction<TeacherSchema>) {
      state.teacher = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        teacher: state.teacher,
      });
    },
    setSubject(state, action: PayloadAction<SubjectSchema>) {
      const oldSubject = state.subject;
      state.subject = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        subject: state.subject,
      });

      // Reset localStorage of subject-specific steps to avoid fetching them for wrong subject ..
      if (oldSubject?.subject.id !== state.subject?.subject.id) {
        state.subtaskPercentages = undefined;
        state.taskPercentages = undefined;
        state.stemTasks = undefined;
        localStorageSync(LocalStorageKeys.examSettings, {
          subtaskPercentages: state.subtaskPercentages,
          baseSubjectTaskPercentages: state.taskPercentages,
          stemTasks: state.stemTasks,
        });
      }
    },
    setSubjectPart(
      state,
      action: PayloadAction<{ name: keyof SubjectSchema; value: string }>,
    ) {
      if (!state.subject) {
        state.subject = initialSubject;
      }
      state.subject[action.payload.name] = action.payload.value;
      localStorageSync(LocalStorageKeys.examSettings, {
        subject: state.subject,
      });
    },
    setExam(state, action: PayloadAction<ExamSchema>) {
      state.exam = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        exam: state.exam,
      });
    },
    setExamTaskTypes(state, action: PayloadAction<DrupalNode[]>) {
      if (!state.exam) {
        state.exam = initialExam;
      }
      state.exam.taskTypes = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        exam: state.exam,
      });
    },
    setSubtaskPercentages(
      state,
      action: PayloadAction<GroupedPercentagesSchema>,
    ) {
      state.subtaskPercentages = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        subtaskPercentages: state.subtaskPercentages,
      });
    },
    setMultipartPercentages(
      state,
      action: PayloadAction<GroupedPercentagesSchema>,
    ) {
      state.multipartPercentages = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        multipartPercentages: state.multipartPercentages,
      });
    },
    setTaskPercentages(state, action: PayloadAction<TaskPercentagesSchema>) {
      state.taskPercentages = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        taskPercentages: state.taskPercentages,
      });
    },
    setStemTasks(state, action: PayloadAction<StemTaskSet>) {
      state.stemTasks = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        stemTasks: state.stemTasks,
      });
    },
    setStemLanguageWeight(state, action: PayloadAction<TaskPercentagesSchema>) {
      const taskWeight = action.payload['groupPercentages'][0];
      if (!state.stemTasks) {
        state.stemTasks = initialStemTaskSet;
      }
      state.stemTasks.weight = taskWeight;
      state.stemTasks.isBilingual = action.payload.isBilingual;
      localStorageSync(LocalStorageKeys.examSettings, {
        stemTasks: state.stemTasks,
      });
    },
    setArtsAndMusicTaskSet(state, action: PayloadAction<ArtsAndMusicTaskSet>) {
      state.artsAndMusicTaskSet = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        artsAndMusicTaskSet: state.artsAndMusicTaskSet,
      });
    },
    setArtsAndMusicNumberOfSubsections(
      state,
      action: PayloadAction<ArtsAndMusicNumberOfSubsectionsInput>,
    ) {
      Object.keys(action.payload).forEach(taskTypeId => {
        const subsections = action.payload[taskTypeId];
        Object.keys(subsections).forEach(subsectionId => {
          const value = subsections[subsectionId];

          state
            .artsAndMusicTaskSet!.taskTypes.find(
              taskType => taskType.id === taskTypeId,
            )!
            .field_section_valuation!.find(
              subsection => subsection.id === subsectionId,
            )!.numberOfSubsections = value;
        });
      });
      localStorageSync(LocalStorageKeys.examSettings, {
        artsAndMusicTaskSet: state.artsAndMusicTaskSet,
      });
    },
    setArtsAndMusicSubsectionWeights(
      state,
      action: PayloadAction<PercentageSchema[]>,
    ) {
      Object.keys(action.payload).forEach(taskTypeId => {
        const sections = action.payload[taskTypeId];
        Object.keys(sections).forEach(sectionId => {
          const values = sections[sectionId];
          const subsections = state
            .artsAndMusicTaskSet!.taskTypes.find(
              taskType => taskType.id === taskTypeId,
            )!
            .field_section_valuation!.find(section => section.id === sectionId)!
            .field_subsection_valuation!;

          subsections.forEach((subsection, index) => {
            subsection.field_percentage = values[index];
          });
        });
      });
      localStorageSync(LocalStorageKeys.examSettings, {
        artsAndMusicTaskSet: state.artsAndMusicTaskSet,
      });
    },
    setArtsAndMusicTaskTypeWeights(
      state,
      action: PayloadAction<PercentageSchema[]>,
    ) {
      Object.keys(action.payload).forEach(taskTypeId => {
        const values = action.payload[taskTypeId];

        const task = state.artsAndMusicTaskSet!.taskTypes.find(
          taskType => taskType.id === taskTypeId,
        ) as DrupalNode;

        const weightedSections =
          task.field_section_valuation?.filter(
            section =>
              section.field_section?.field_type !==
              DrupalSectionTypes.isLanguageSection,
          ) || [];

        const mappings = {};
        values.forEach((value, index) => {
          const id = weightedSections[index].id;
          mappings[id] = value;
        });

        task.taskTypeWeights = mappings;
      });
      localStorageSync(LocalStorageKeys.examSettings, {
        artsAndMusicTaskSet: state.artsAndMusicTaskSet,
      });
    },
    setModernLanguagePartWeights(
      state,
      action: PayloadAction<PercentageGroup>,
    ) {
      const weights = action.payload.groupPercentages;
      weights.forEach((weight, index) => {
        state.exam!.examComponents!.field_exam_part_valuation![
          index
        ].field_percentage = weight;
      });
      localStorageSync(LocalStorageKeys.examSettings, {
        exam: state.exam,
      });
    },
    setModernLanguageSupersectionWeights(
      state,
      action: PayloadAction<GroupedPercentagesSchema>,
    ) {
      const keys = Object.keys(action.payload);
      keys.forEach((key, partIndex) => {
        const values = action.payload[key];
        const part = state.exam!.examComponents!.field_exam_part_valuation![
          partIndex
        ];
        values.forEach((value, valueIndex) => {
          part!.field_supersection_valuation![
            valueIndex
          ].field_percentage = value;
        });
      });
      localStorageSync(LocalStorageKeys.examSettings, {
        exam: state.exam,
      });
    },
    setModernLanguageTasks(
      state,
      action: PayloadAction<Record<string, string[]>>,
    ) {
      state.modernLanguageTasks = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        modernLanguageTasks: state.modernLanguageTasks,
      });
    },
    setModernLanguageTaskWeights(
      state,
      action: PayloadAction<GroupedPercentagesSchema>,
    ) {
      state.modernLanguageTaskWeights = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        modernLanguageTaskWeights: state.modernLanguageTaskWeights,
      });
    },
    setAncientLanguageDefaults(
      state,
      action: PayloadAction<AncientLanguageDefaults>,
    ) {
      state.ancientLanguageDefaults = action.payload;
      localStorageSync(LocalStorageKeys.examSettings, {
        ancientLanguageDefaults: state.ancientLanguageDefaults,
      });
    },
    setAllExamSettings(state, action: PayloadAction<ExamSettingsState>) {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
        localStorageSync(LocalStorageKeys.examSettings, {
          [key]: action.payload[key],
        });
      });
    },
    resetSubjectSpecificSettings(state) {
      state = {
        teacher: state.teacher,
        subject: state.subject,
        ...initialState,
      };
      localStorageClear(LocalStorageKeys.examSettings);
      localStorageSync(LocalStorageKeys.examSettings, {
        teacher: state.teacher,
        subject: state.subject,
      });
    },
    resetExamSettings(state) {
      state = initialState;
      localStorageClear(LocalStorageKeys.examSettings);
    },
  },
});

// Exports
export const { name: sliceKey, actions } = examSettingsSlice;
export default examSettingsSlice.reducer;
