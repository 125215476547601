/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { HelmetProvider } from 'react-helmet-async';

// Use consistent styling
import 'sanitize.css/sanitize.css';

import { configureAppStore } from 'store/configureStore';

// import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';

import { ConnectedRouter } from 'connected-react-router';
import { history } from './store/history';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// lazy load of root app
// import { App } from 'app';
const App = React.lazy(() => import('./app'));

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <ConnectedRouter history={history}>
        <React.Suspense fallback={<div />}>
          <App />
        </React.Suspense>
      </ConnectedRouter>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });

  if (process.env.NODE_ENV === 'development') {
    module.hot.accept('./store/reducers', () => {
      const newRootReducer = require('./store/reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
