export interface StemTaskSet {
  id: string;
  tasks: StemTaskSchema[];
  comments?: string;
  maxPoints?: number;
  pointsGiven?: number;
  weight?: number;
  grade?: number;
  isBilingual?: boolean;
}
export interface StemTaskSchema {
  id: string;
  name: string;
  variants: StemTaskVariantSchema[];
  selectedVariant?: number;
  maxPoints?: number;
  pointsGiven?: number;
}
export interface StemTaskEditorFormInputs {
  maxPoints?: number;
  partialPerformance?: string;
  subtask?: string;
  variant?: string;
  taskName?: string;
}
export interface StemTaskVariantSchema {
  id: string;
  name: string;
  subtasks: StemSubtaskSchema[];
}

export interface StemSubtaskSchema {
  id: string;
  name: string;
  partialPerformances: StemPartialPerformanceSchema[];
}

export interface StemPartialPerformanceSchema {
  id: string;
  description: string;
  maxPoints: number;
  pointsGiven?: number;
  comments?: string;
}

export const initialStemTaskSet: StemTaskSet = {
  id: '',
  tasks: [],
};

export enum AddStemTasksFormFields {
  maxPoints = 'maxPoints',
  partialPerformances = 'partialPerformances',
  subtask = 'subtask',
  taskVariant = 'taskVariant',
  taskName = 'taskName',
}
