import { DrupalNode } from '../../../features/ApiData';

export interface SubjectSchema {
  subjectGroup: any;
  subject: any;
  subjectVariant?: DrupalNode;
}

export const initialSubject = {
  subjectGroup: '',
  subject: '',
};
