import { createSlice } from '../../../utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { ConfigState } from './types';

const staticConfig: any = window['appConfig'];

export const initialState: ConfigState = {
  api: {
    apiUrl: staticConfig?.apiUrl || '',
    statisticsUrl: staticConfig?.statisticsUrl || '',
    version: staticConfig?.apiVersion || '',
    username: staticConfig?.drupalUser || '',
    password: staticConfig?.drupalPassword || '',
    frontEndVersion: staticConfig?.frontEndVersion || '',
  },
  germanyState: staticConfig.germanyState || '',
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
});

// Exports
export const { name: sliceKey } = configSlice;
export default configSlice.reducer;
