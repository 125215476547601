import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../utils/LocalStorageHandler/localStorageKeys';
import {
  localStorageClear,
  localStorageLoad,
  localStorageSync,
} from '../../../utils/LocalStorageHandler/localStorageUtils';
import { ApiRequestError } from '../types';
import {
  GradeMappingsCollection,
  InstructionsManualEntry,
  MiscStaticContent,
  StaticContentState,
} from './types';

const KEY = LocalStorageKeys.staticContent;
const persisted: StaticContentState = localStorageLoad(KEY);

export const initialState: StaticContentState = {
  mappings: persisted?.mappings ?? {},
};

const staticContentSlice = createSlice({
  name: 'staticContent',
  initialState,
  reducers: {
    loadGradeMappings(_) {},
    setGradeMappings(state, action: PayloadAction<GradeMappingsCollection>) {
      state.mappings = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        mappings: state.mappings,
      });
    },
    loadFaqPage(_) {},
    setFaqPage(state, action: PayloadAction<string>) {
      state.faq = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        imprint: state.faq,
      });
    },
    loadImprintPage(_) {},
    setImprintPage(state, action: PayloadAction<string>) {
      state.imprint = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        imprint: state.imprint,
      });
    },
    loadMisc(_) {},
    setMisc(state, action: PayloadAction<MiscStaticContent>) {
      state.misc = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        misc: state.misc,
      });
    },
    loadNews(_) {},
    setNews(state, action: PayloadAction<string>) {
      state.news = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        news: state.news,
      });
    },
    loadPrivacyPage(_) {},
    setPrivacyPage(state, action: PayloadAction<string>) {
      state.privacy = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        privacy: state.privacy,
      });
    },
    loadInstructionsManualPage(_) {},
    setInstructionsManualPage(
      state,
      action: PayloadAction<InstructionsManualEntry[]>,
    ) {
      state.instructionsManual = action.payload;
      state.error = null;
      localStorageClear(KEY);
      localStorageSync(KEY, {
        privacy: state.instructionsManual,
      });
    },
    setError(state, action: PayloadAction<ApiRequestError>) {
      state.error = action.payload;
    },
    resetStaticContent(state) {
      state = initialState;
      localStorageClear(LocalStorageKeys.examSettings);
    },
  },
});

export const { name: sliceKey, actions } = staticContentSlice;
export default staticContentSlice.reducer;
