import { PayloadAction } from '@reduxjs/toolkit';
import { SubjectSchema } from '../../model/schemas/ExamSettings/SubjectSchema';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import { LocalStorageKeys } from '../../../utils/LocalStorageHandler/localStorageKeys';
import {
  localStorageClear,
  localStorageLoad,
  localStorageSync,
} from '../../../utils/LocalStorageHandler/localStorageUtils';
import { DrupalNames } from '../../api/utils/constants';
import { ApiDataState } from './types';
import { ApiRequestError } from '../types';

const persisted = localStorageLoad(LocalStorageKeys.apiData) as ApiDataState;
const initialTaxonmoies = {
  [DrupalNames.taxonomy_term__subject_group]: [],
  [DrupalNames.taxonomy_term__course_type]: [],
  [DrupalNames.taxonomy_term__exam_type]: [],
};
export const initialState: ApiDataState = {
  request: {
    taxonomies: {
      [DrupalNames.taxonomy_term__subject_group]: {
        isLoading: false,
        error: null,
      },
      [DrupalNames.taxonomy_term__course_type]: {
        isLoading: false,
        error: null,
      },
      [DrupalNames.taxonomy_term__exam_type]: { isLoading: false, error: null },
    },
    contentTypes: { isLoading: false, error: null },
    nodes: { isLoading: false, error: null },
  },
  taxonomies: persisted?.taxonomies ?? initialTaxonmoies,
  contentTypes: persisted?.contentTypes ?? {},
  nodes: persisted?.nodes ?? {},
  nodeIdsByType: {},
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const key = 'apiData';
const apiDataSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    requestError(
      state,
      action: PayloadAction<{ requestType: string; error: ApiRequestError }>,
    ) {
      state.request[action.payload.requestType].isLoading = false;
      state.request[action.payload.requestType].error = action.payload.error;
    },
    loadTaxonomyTerms(state, action: PayloadAction<{ taxonomyType: string }>) {
      state.request.taxonomies[action.payload.taxonomyType].isLoading = true;
      state.request.taxonomies[action.payload.taxonomyType].error = null;
    },
    setTaxonomy(state, action: PayloadAction<{ type: string; data }>) {
      state.request.taxonomies[action.payload.type].isLoading = false;
      state.taxonomies[action.payload.type] = action.payload.data;
      localStorageSync(LocalStorageKeys.apiData, {
        taxonomies: state.taxonomies,
      });
    },
    loadTaxonomyTermsError(
      state,
      action: PayloadAction<{ type: string; error: ApiRequestError }>,
    ) {
      state.request.taxonomies[action.payload.type].isLoading = false;
      state.request.taxonomies[action.payload.type].error =
        action.payload.error;
    },
    setContentTypes(state, action: PayloadAction<{ data }>) {
      action.payload.data.forEach(item => {
        state.contentTypes[item.id] = item;
      });
      localStorageSync(LocalStorageKeys.apiData, {
        contentTypes: state.contentTypes,
      });
    },
    loadAllSubjects(state) {
      state.request.nodes.isLoading = true;
      state.request.nodes.error = null;
    },
    loadSubjectDetails(state, action: PayloadAction<SubjectSchema>) {
      state.request.nodes.isLoading = true;
      state.request.nodes.error = null;
    },
    loadSubjectSuccess(
      state,
      action: PayloadAction<{ type: string; node: any }>,
    ) {
      state.request.nodes.isLoading = false;
      state.request.contentTypes.isLoading = false;
      state.request.nodes.error = null;
      let node = action.payload.node;
      state.nodes[node.id] = node;
      // persist state.nodes in localStorage
      localStorageSync(LocalStorageKeys.apiData, { nodes: state.nodes });
    },
    loadAllSubjectsSuccess(
      state,
      action: PayloadAction<{ type: string; nodes: any }[]>,
    ) {
      state.request.nodes.isLoading = false;
      state.request.contentTypes.isLoading = false;
      state.request.nodes.error = null;
      action.payload.forEach(item => {
        state.nodeIdsByType[item.type] = [];
        item.nodes.forEach(node => {
          state.nodeIdsByType[item.type].push(node.id);
          state.nodes[node.id] = node;
        });
        // persist state.nodes in localStorage
        localStorageSync(LocalStorageKeys.apiData, { nodes: state.nodes });
      });
    },
    resetData(state) {
      state.taxonomies = initialTaxonmoies;
      state.contentTypes = {};
      state.nodes = {};
      localStorageClear(LocalStorageKeys.apiData);
    },
  },
});

export const { name: sliceKey, actions } = apiDataSlice;
export default apiDataSlice.reducer;
