import { DrupalNode } from '../../../features/ApiData';

export interface ExamSchema {
  courseType: any;
  examType: any;
  semester?: string;
  examNumber?: string;
  examComponents?: DrupalNode;
  taskTypes?: any[];
  correctionProcedure?: any;
  deselectTasks?: DrupalNode[];
  baseSubjectTaskSelection?: string[];
}

export const initialExam = {
  courseType: '',
  examType: '',
};
export enum CourseTypeName {
  DQClass = 'DQ-Klasse',
}
