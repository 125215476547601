export enum DrupalNames {
  taxonomy_term__course_type = 'taxonomy_term--course_type',
  taxonomy_term__exam_type = 'taxonomy_term--exam_type',
  taxonomy_term__subject_group = 'taxonomy_term--subject_group',
  node_type__node_type = 'node_type--node_type',
  paragraph__arts_and_music_task_type = 'paragraph--arts_and_music_task_type',
  paragraph__base_task_type = 'paragraph--base_task_type',
  paragraph__base_tasks = 'paragraph--base_tasks',
  paragraph__criterion = 'paragraph--criterion',
  paragraph__criterion_per_couse_type = 'paragraph--criteria_per_course_type',
  paragraph__german_task_type = 'paragraph--german_task_type',
  paragraph__ml_exam_part_valuation = 'paragraph--ml_exam_part_valuation',
  paragraph__modern_languages_exam_type = 'paragraph--modern_languages_exam_type',
  paragraph__modern_languages_tasks = 'paragraph--modern_languages_tasks',
  paragraph__multipart_section_valuation = 'paragraph--multipart_section_valuation',
  paragraph__multipart_valuation = 'paragraph--multipart_valuation',
  paragraph__repeated_subsection_secvaluation = 'paragraph--repeated_subsection_secvaluation',
  paragraph__section_valuation = 'paragraph--section_valuation',
  paragraph__stem_task = 'paragraph--stem_task',
  paragraph__subsection_valuation = 'paragraph--subsection_valuation',
  paragraph__supersection_valuation = 'paragraph--supersection_valuation',
  paragraph__gp_supersection_valuation = 'paragraph--gp_supersection_valuation',
  node__section = 'node--section',
  node__subsection = 'node--subsection',
  value_range__value_range = 'value_range--value_range',
}

export enum DrupalContentTypes {
  section = 'section',
  stem_task_set = 'stem_task_set',
  subsection = 'subsection',
}

export enum DrupalSubjectTypes {
  ancient_language = 'ancient_language',
  arts_and_music = 'arts_and_music',
  base_subject = 'base_subject',
  german = 'german',
  modern_language = 'modern_language',
  modern_language_alias = 'modern_language_alias',
  old_ancient_language = 'old_ancient_language',
  stem = 'stem',
  stem_centralized_abitur = 'stem_centralized_abitur',
}

export function getNodeType(contentType: string) {
  return `node--${contentType}`;
}

export function getNodeName(contentType?: string) {
  if (!contentType) return '';
  return contentType.replace(/node--/, '');
}

export const ApiEndpoints = {
  [DrupalNames.taxonomy_term__course_type]: 'taxonomy_term/course_type',
  [DrupalNames.taxonomy_term__exam_type]: 'taxonomy_term/exam_type',
  [DrupalNames.taxonomy_term__subject_group]: 'taxonomy_term/subject_group',
  [DrupalNames.node_type__node_type]: 'node_type/node_type',
};

export enum DrupalTextBlocksEnum {
  textBlock_5 = 'field_text_block_5',
  textBlock_4 = 'field_text_block_4',
  textBlock_3 = 'field_text_block_3',
  textBlock_2 = 'field_text_block_2',
  textBlock_1 = 'field_text_block_1',
  textBlock_0 = 'field_text_block_0',
}

export const DrupalTextBlocks = [
  DrupalTextBlocksEnum.textBlock_5,
  DrupalTextBlocksEnum.textBlock_4,
  DrupalTextBlocksEnum.textBlock_3,
  DrupalTextBlocksEnum.textBlock_2,
  DrupalTextBlocksEnum.textBlock_1,
  DrupalTextBlocksEnum.textBlock_0,
];

export enum DrupalTaskTypes {
  taskWork = 'Aufgabenbearbeitung',
  languageUse = 'Sprachverwendung',
}

export enum DrupalSubjects {
  germanAfter22_23 = 'Deutsch ab 22/23',
}

export enum DrupalSectionTypes {
  default = 'default',
  isLanguageSection = 'is_language_section',
  without_language_evaluation = 'without_language_evaluation',
}

export const fieldQuestionNotAnswered = 'nicht bearbeitet';
export const shortExamOptionLength = 45;
