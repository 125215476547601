import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { convertLanguageJsonToObject } from './translations';

import de from './de/translation.json';

export const DEFAULT_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES: string[] = [DEFAULT_LANGUAGE];

const dateAndNumberTranslations = {
  formattedIntlDate: '{{ date, intlDate }}',
  formattedNumber: '{{ value, number }}',
  formattedNumberWithoutInteger: '{{ value, number }}',
  formattedArtsAndMusicPercentage: '{{ value, artsAndMusicPercentage }}',
  formattedSingleDecimal: '{{ value, singleDecimal }}',
};
export const localResources = {
  de: { translation: { ...de, ...dateAndNumberTranslations } },
};

export enum FormatTypes {
  formattedIntlDate = 'formattedIntlDate',
  formattedNumber = 'formattedNumber',
  formattedArtsAndMusicPercentage = 'formattedArtsAndMusicPercentage',
  formattedSingleDecimal = 'formattedSingleDecimal',
}

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(de);

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: DEFAULT_LANGUAGE, // overrides language detection
    resources: localResources,
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    load: 'languageOnly',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(value);
        }
        if (format === 'number') {
          return value.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
        }
        if (format === 'artsAndMusicPercentage') {
          if (value % 1 === 0) {
            return value;
          }
          return value.toLocaleString('de-DE', {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          });
        }
        if (format === 'singleDecimal') {
          return value.toLocaleString('de-DE', {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          });
        }
        return value;
      },
    },
    keySeparator: false, // use false for a flat json; default value is '.'
  });
