import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from '../../../types';

// First makeSelect the relevant part from the state
const selectContent = (state: RootState) => state.staticContent || initialState;

export const selectFaqPage = createSelector(
  selectContent,
  content => content.faq,
);

export const selectGradeMappings = createSelector(
  selectContent,
  content => content.mappings,
);

export const selectImprintPage = createSelector(
  selectContent,
  content => content.imprint,
);

export const selectMisc = createSelector(
  selectContent,
  content => content.misc,
);

export const selectNews = createSelector(
  selectContent,
  content => content.news,
);

export const selectPrivacyPage = createSelector(
  selectContent,
  content => content.privacy,
);

export const selectInstructionsManual = createSelector(
  selectContent,
  content => content.instructionsManual,
);

export const requestGradeMappingsStatus = createSelector(
  selectContent,
  state => {
    return {
      mappings: state.mappings,
      error: state.error,
    };
  },
);
